import { useState, useEffect, useRef } from "react";

import './GeneralTourPoint.css';

import Img from "Img";

import { GetDataPage } from "interface/Data";

export default function Page_GeneralTourPoint(props){

    const ShowPositionInput = useRef();
    const [ dataPage, setDataPage ] = useState(GetDataPage('general_tour'));
    const [ positionClick, setPositionClick ] = useState(GetDataPage('position_click'));

    const [ position, setPosition ]       = useState(0);
    const [ smooth, setSmooth ]           = useState(0);
    const [ oldPosition, setOldPosition ] = useState(0);
    
    const [ listImg, setListImg ] = useState([]);

    const [ startPosition, setStartPosition ] = useState(false);
    let speed   = 0;
    let time    = 0;
    let elapsed = 0;

    const [ isMoving, setIsMoving ] = useState(false);

    function LoadImg(){
        let count = 0;
        const newDate = [];
        dataPage[0].list_img.map((elem, index)=>{
            fetch(props.folder + "general_tour/file/" + elem.img).then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader   = new FileReader();
                reader.onload  = ()=>{
                    count++;
                    newDate[index] = { "id": index, "id_img": elem.id, "id_click": elem.id_click, "position_click": elem.position_click, "url": reader.result };

                    if(dataPage[0].list_img.length == count){
                        setListImg([...newDate]);

                        props.setLoading(false);
                    }
                }
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
        });
    }

    function AltMovieAnimation(){
        const now = Date.now();
        const deltaTime = (now - time) / 1000;
        elapsed += deltaTime;
        time = now;

        let delta = elapsed * speed;
        if(delta >= 1){
            setSmooth(position);
            setOldPosition(position);
            setIsMoving(true);

            setTimeout(() => {
                ShowPointClick();                
            }, 1000);

        }else {
            setIsMoving(false);
            setSmooth(Math.round(lerp(oldPosition, position, delta)));
            window.requestAnimationFrame(AltMovieAnimation);
        }
    }

    function AltSmoothStart(){
        speed = (1 - (Math.abs(position - oldPosition) / (dataPage[0].list_img.length - 1))) * 10;        
        elapsed = 0;
        time    = Date.now();
        AltMovieAnimation();
    }

    function lerp(start, end, amt){
        return (1-amt) * start + amt * end;
    }

    function FindProportion(startPosition, endPosition, currentPosition){
        return (((currentPosition - startPosition) * 100) / (endPosition - startPosition)) * 0.01;
    }

    function ShowPointClick(){
        if(isMoving){
            let dataImg = listImg[position];
            let medidaDiv = document.querySelector('.img_sun');
            return(
                dataImg ?                    
                    positionClick.map((elem, index)=>{
                        let infProportion = 0;
                        let positionX = 0;
                        let positionY = 0;
                        let left = 0;
                        let top = 0;

                        if(dataImg.id_img < elem.left_id_img || dataImg.id_img > elem.right_id_img){    
                            return "";
                        }else {                
                            if(elem.center_id_img >= dataImg.id_img){
                                infProportion = FindProportion(elem.left_id_img, elem.center_id_img, dataImg.id_img);
                                positionX = lerp(elem.left_x, elem.center_x, infProportion);
                                positionY = lerp(elem.left_y, elem.center_y, infProportion);
                                
                                left = medidaDiv.clientWidth * positionX;
                                top = medidaDiv.clientHeight * positionY;
                            }else {
                                infProportion = FindProportion(elem.center_id_img, elem.right_id_img, dataImg.id_img);
                                positionX = lerp(elem.center_x, elem.right_x, infProportion);
                                positionY = lerp(elem.center_y, elem.right_y, infProportion);
                                
                                left = medidaDiv.clientWidth * positionX;
                                top = medidaDiv.clientHeight * positionY;
                            }
                            
                            return(
                                <div className="div_show_point" style={ { left: left + "px", top: top + "px" } } key={ index }>
                                    { elem.title }
                                </div>
                            )
                        }
                    })
                : null
            )
        }
    }

    useEffect(()=>{
        props.setLoading(true);
        
        setTimeout(() => {
            setStartPosition(true);
        }, 1000);  

        LoadImg();
    }, []);

    useEffect(()=>{
        if(startPosition == true){
            AltSmoothStart();
        }
    }, [position]);

    return(
        <div className="Page_GeneralTourPoint">
            <div className="img_sun">
                {
                    ShowPointClick()
                }
                {
                    listImg.map((elem, index)=>{
                        return(
                            <div className="div_show_img" key={ index } style={ { display: index == smooth ? "block" : "none" } }>
                                <Img listImg={ elem.url } showImg={ index == smooth ? true : false } key={ index } />
                            </div>
                        )
                    })
                }
            </div>

            <div className="btn">
                <div className="line_position_sun" onClick={ ()=>{ } }>
                    <input type="range" id="ShowPositionInput" ref={ ShowPositionInput } className="input_range" min="0" max={ (dataPage[0].list_img.length - 1) } step={ 1 } onChange={ (e)=>{ setPosition(e.target.value); } } value={ position } />
                </div>
            </div>
        </div>
    )
}