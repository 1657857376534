import { memo } from "react";

import './Img.css';

function Img(props){

    return(
        <div className="show_img_project" style={ { display: props.showImg ? "block" : "none" } }>
            <img alt="img" src={ props.listImg } className="img_memo" />
        </div>
    )
}

export default memo(Img);