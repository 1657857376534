import { useState, useEffect } from "react";

import './Home.css';

import { GetDataPage } from "interface/Data";

export default function Page_Home(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('screen_initial'));
    
    return(
        <div className="Page_Home">
            <div className="logotipo">
                <img alt="img" src={ props.folder + "screen_initial/" + dataPage.logo } className="logo" />
            </div>
            <div className="title">{ dataPage.title }</div>
        </div>
    )
}