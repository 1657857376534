import { useState, useEffect, useRef } from "react";

import './Solar.css';

import Img from "Img";

import { GetDataPage } from "interface/Data";

import { SvgMoon, SvgMorning, SvgSunMid } from "SvgFile";

export default function Page_Solar(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('solar'));

    const [ position, setPosition ]       = useState(0);
    const [ smooth, setSmooth ]           = useState(0);
    const [ oldPosition, setOldPosition ] = useState(0);
    
    const [ listImg, setListImg ] = useState([]);

    const [ startPosition, setStartPosition ] = useState(false);
    let speed   = 0;
    let time    = 0;
    let elapsed = 0;
    
    function LoadImg(){
        let count = 0;
        const newDate = [];

        dataPage[0].list_img.map((elem, index)=>{
            fetch(props.folder + "solar/" + dataPage[0].code + "/mobile/" + elem.img).then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader   = new FileReader();
                reader.onload  = ()=>{
                    count++;
                    newDate[index] = { "id": index, "url": reader.result };

                    if(dataPage[0].list_img.length == count){
                        setListImg([...newDate]);

                        props.setLoading(false);
                    }
                }
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
        });
    }

    function AltMovieAnimation(){
        const now = Date.now();
        const deltaTime = (now - time) / 1000;
        elapsed += deltaTime;
        time = now;

        let delta = elapsed * speed;
        if(delta >= 1){
            setSmooth(position);
            setOldPosition(position);
        }else {
            setSmooth(Math.round(lerp(oldPosition, position, delta)));
            window.requestAnimationFrame(AltMovieAnimation);
        }
    }

    function AltSmoothStart(){
        speed = (1 - (Math.abs(position - oldPosition) / (dataPage[0].list_img.length - 1))) * 10;        
        elapsed = 0;
        time    = Date.now();
        AltMovieAnimation();
    }

    function lerp (start, end, amt){
        return (1-amt) * start + amt * end;
    }

    function OpenClick(type){
        if(type == 'initial'){
            AltSmoothStart()
            let count = position;
            const countSetInterval = setInterval(() => {
                count--;
                setPosition(count);
                if(count == 0){
                    clearInterval(countSetInterval);
                }
            }, 16);

        }else if(type == 'center'){
            AltSmoothStart()
            let count    = position;
            let countMid = (dataPage[0].list_img.length - 1) / 2;
            const countSetInterval = setInterval(() => {
                if(countMid > count){
                    count++;
                }else if(countMid < count){
                    count--;
                }

                setPosition(count);
                if(count == Math.round((dataPage[0].list_img.length - 1) / 2)){
                    clearInterval(countSetInterval);
                }
            }, 16);

        }else if(type == 'end'){
            AltSmoothStart()
            let count = position;
            const countSetInterval = setInterval(() => {
                count++;
                setPosition(count);
                if(count == (dataPage[0].list_img.length - 1)){
                    clearInterval(countSetInterval);
                }
            }, 16);
        }
    }

    useEffect(()=>{
        props.setLoading(true);
        
        setTimeout(() => {
            setStartPosition(true);
        }, 1000);        

        LoadImg();
    }, []);

    useEffect(()=>{
        if(startPosition == true){
            AltSmoothStart()
        }
    }, [position]);

    return(
        <div className="Page_Solar">            
            <div className="img_sun">
                {
                    listImg.map((elem, index)=>{
                        return(
                            <Img listImg={ elem.url } showImg={ index == smooth ? true : false } key={ index } />
                        )
                    })
                }
            </div>

            <div className="btn">
                <div className="show_icon">
                    <div className="div_sun_icon" onClick={ ()=>{ OpenClick('initial') } }>
                        <SvgMorning className="icon_day" color="#ededed" />
                    </div>
                    <div className="div_sun_icon" onClick={ ()=>{ OpenClick('center') } }>
                        <SvgSunMid className="icon_day" color="#ededed" />
                    </div>
                    <div className="div_sun_icon" onClick={ ()=>{ OpenClick('end') } }>
                        <SvgMoon className="icon_day" color="#ededed" />
                    </div>
                </div>
                <div className="line_position_sun" onClick={ ()=>{ } }>
                    <input type="range" className="input_range" min="0" max={ (dataPage[0].list_img.length - 1) } step={ 1 } onChange={ (e)=>{ setPosition(e.target.value) } } value={ position } />
                </div>
            </div>
        </div>
    )
}