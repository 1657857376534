import { useState, useEffect, useRef } from "react";

import './Apartments.css';

import Img from "Img";

import { GetDataPage } from "interface/Data";
import { CanvasMask } from "interface/Canvas";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Apartments(props){

    const [ loadingFinished, setLoadingFinished ] = useState(false);

    const [ apartments, setApartments ]         = useState(GetDataPage('apartments'));
    const [ apartmentsFile, setApartmentsFile ] = useState(GetDataPage('apartments_file'));

    const [ inputRadio, setInputRadio ]   = useState(0);
    const [ position, setPosition ]       = useState(0);
    const [ smooth, setSmooth ]           = useState(0);
    const [ oldPosition, setOldPosition ] = useState(0);
    
    const [ listImg, setListImg ] = useState([]);

    const [ startPosition, setStartPosition ] = useState(false);
    let speed   = 0;
    let time    = 0;
    let elapsed = 0;

    /* data create canvas */    
    const newDateImage = [];
    const maskLink     = [];

    // CanvasMask.canvasFixed = document.getElementById('canvas_teste');
    let canvas  = document.getElementById('canvas_teste');
    let context = null;   
    
    const [ showMaskFixed, setShowMaskFixed ] = useState(true);
    const [ listMask, setListMask ] = useState([
        { "mask": new Image() },
        { "mask": new Image() },
        { "mask": new Image() },
        { "mask": new Image() },
        { "mask": new Image() },
        { "mask": new Image() },
        { "mask": new Image() },
        { "mask": new Image() }
    ]);
    
    // maskHover = document.getElementById('mask_hover');
    let contextHover = null;
    let maskHover = null;

    let overlayColor          = 'rgba(0, 255, 0, 0.3)';
    let overlayHighlightColor = 'rgba(255, 0, 0, 0.3)';

    let colorToCoordMap = new Map();
    /* end */  

    function LoadImg(){
        let count = 0;
        const newDate = [];
        apartmentsFile.map((elem, index)=>{
            fetch("https://dash-movala.chroma-garden.com/assets/apartments/file/" + elem.img).then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader   = new FileReader();
                reader.onload  = ()=>{
                    count++;

                    newDateImage[index] = { "id": index, "url": reader.result, "img": elem.img, "mask": "" };
                    newDate[index] = { "id": index, "url": reader.result };

                    if(elem.imgFixed == true){
                        maskLink.push({ "index": index, "mask": elem.mask });
                    }

                    if(apartmentsFile.length == count){
                        LoadMask();
                    }
                }
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
        });
    }

    function LoadMask(){
        let count = 0;
        const newData = [...listMask];
        maskLink.map((elem, index)=>{
            // fetch(props.folder + "apartments/mask/" + elem.mask).then(responseMask => responseMask.blob())
            fetch("https://dash-movala.chroma-garden.com/assets/apartments/mask/" + elem.mask).then(responseMask => responseMask.blob())
            .then(blobMask => new Promise((resolve, reject) => {
                const readerMask   = new FileReader();
                readerMask.onload  = ()=>{
                    count++;
                    
                    newDateImage[elem.index]['mask'] = readerMask.result;
                    newData[index]['mask'].src       = readerMask.result

                    if(maskLink.length == count){
                        setListImg([...newDateImage]);

                        setLoadingFinished(true);

                        props.setLoading(false);
                    }
                }
                readerMask.onerror = reject
                readerMask.readAsDataURL(blobMask)
            }));
        });
        setListMask(newData);
    }

    function AltMovieAnimation(){
        const now = Date.now();
        const deltaTime = (now - time) / 1000;
        elapsed += deltaTime;
        time = now;

        let delta = elapsed * speed;
        if(delta >= 1){
            setSmooth(position);
            setOldPosition(position);
        }else {
            setSmooth(Math.round(lerp(oldPosition, position, delta)));
            window.requestAnimationFrame(AltMovieAnimation);
        }
    }

    function AltSmoothStart(){
        speed = (1 - (Math.abs(position - oldPosition) / (apartmentsFile.length - 1))) * 10;        
        elapsed = 0;
        time    = Date.now();
        AltMovieAnimation();
    }

    function lerp (start, end, amt){
        return (1-amt) * start + amt * end;
    }

    function OpenClick(value){  
        let newData  = apartmentsFile.filter(item => item.imgFixed == true);        
        let indexImg = apartmentsFile.findIndex(item => item.img == newData[value].img);

        AltSmoothStart();
        setInputRadio(value);

        let count = position;
        const countSetInterval = setInterval(() => {
            if(indexImg > count){
                count++;
            }else if(indexImg < count){
                count--;
            }
            setPosition(count);
            if(count == indexImg){
                CreateMask(value);
                clearInterval(countSetInterval);
            }
        }, 14);
    }

    function CreateMask(value){
        context   = canvas.getContext('2d', { willReadFrequently: true });
        maskHover = document.getElementById('mask_hover').getContext('2d', { willReadFrequently: true });

        context.clearRect(0, 0, canvas.width, canvas.height);

        maskHover.canvas.width  = canvas.width;
        maskHover.canvas.height = canvas.height;

        maskHover.drawImage(listMask[value].mask, 0, 0, canvas.width, canvas.height);

        CanvasMask.canvasHover = maskHover;
        generateColorCoordMap(); 

        canvas.addEventListener('mousemove', (e)=>{ handleMouseMove(e) } );

        drawInitialCanvas('show_color');
    }

    function generateColorCoordMap() {
        colorToCoordMap.clear();
        const maskData = maskHover.getImageData(0, 0, canvas.width, canvas.height).data;
        for (let i = 0; i < maskData.length; i += 4) {
            const r = maskData[i];
            const g = maskData[i + 1];
            const b = maskData[i + 2];
            const a = maskData[i + 3];
            // Skip black or almost black pixels and fully transparent pixels
            if ((r > 0 || g > 0 || b > 0) && a !== 0) {
                const color = `rgba(${r}, ${g}, ${b}, ${a})`;
                if (!colorToCoordMap.has(color)) {
                    colorToCoordMap.set(color, []);
                }else {
                    colorToCoordMap.get(color).push({ x: (i / 4) % canvas.width, y: Math.floor(i / 4 / canvas.width) });
                }
            }
        }
    }

    function handleMouseMove(event) {
        requestAnimationFrame(() => {
            context   = canvas.getContext('2d', { willReadFrequently: true });
            context.clearRect(0, 0, canvas.width, canvas.height);

            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            
            let canvasHoverColor = document.getElementById('mask_hover').getContext('2d');
            const pixelData = canvasHoverColor.getImageData(x, y, 1, 1).data;
            const currentColor = `rgba(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]}, ${pixelData[3]})`;

            if (pixelData[0] > 0 || pixelData[1] > 0 || pixelData[2] > 0 && pixelData[3] !== 0) {
                if(colorToCoordMap.get(currentColor)){
                    context.fillStyle = overlayHighlightColor;                    
                    colorToCoordMap.get(currentColor).forEach(coord => {
                        context.fillRect(coord.x, coord.y, 1, 1);
                    });
                }
            }else {
                drawInitialCanvas('show_color');
                // context.clearRect(0, 0, canvas.width, canvas.height);
            }
        });
    }

    function drawInitialCanvas(type) {                 
        let canvasHoverColor = document.getElementById('mask_hover').getContext('2d');

        const imageData = canvasHoverColor.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        const coresUnicas = [];
        for (let i = 0; i < data.length; i += 4) {
            const cor = `rgba(${data[i]}, ${data[i + 1]}, ${data[i + 2]}, 255)`;            
            // Adiciona a cor ao array se ainda não estiver presente
            if (!coresUnicas.includes(cor)) {
                coresUnicas.push(cor);
            }
        }
        
        const indexColor = coresUnicas.indexOf('rgba(0, 0, 0, 1)');
        coresUnicas.splice(indexColor, 1);      
        
        if(type == "show_color"){ 
            if(showMaskFixed == true){
                context   = canvas.getContext('2d', { willReadFrequently: true });
                coresUnicas.forEach(element => {
                    context.fillStyle = overlayColor;  
                    if(colorToCoordMap.get(element)){ 
                        colorToCoordMap.get(element).forEach(coord => {
                            context.fillRect(coord.x, coord.y, 1, 1);  
                        }) 
                    }
                });
                
            }else {
                // context   = canvas.getContext('2d', { willReadFrequently: true });
                // coresUnicas.forEach(element => {
                //     context.fillStyle = 'rgba(255 255 255, 0)';  
                //     if(colorToCoordMap.get(element)){ 
                //         colorToCoordMap.get(element).forEach(coord => {
                //             context.fillRect(coord.x, coord.y, 1, 1);  
                //         }) 
                //     }
                // });
                context = canvas.getContext('2d', { willReadFrequently: true });
                context.clearRect(0, 0, canvas.width, canvas.height); 
            }

        }else if(type == "create_show_color"){
            context   = canvas.getContext('2d', { willReadFrequently: true });
            maskHover = document.getElementById('mask_hover').getContext('2d', { willReadFrequently: true });

            context.clearRect(0, 0, canvas.width, canvas.height);

            maskHover.canvas.width  = canvas.width;
            maskHover.canvas.height = canvas.height;

            maskHover.drawImage(listMask[inputRadio].mask, 0, 0, canvas.width, canvas.height);

            CanvasMask.canvasHover = maskHover;
            generateColorCoordMap(); 

            canvas.addEventListener('mousemove', (e)=>{ handleMouseMove(e) } );

            context   = canvas.getContext('2d', { willReadFrequently: true });
            coresUnicas.forEach(element => {
                context.fillStyle = overlayColor;  
                if(colorToCoordMap.get(element)){ 
                    colorToCoordMap.get(element).forEach(coord => {
                        context.fillRect(coord.x, coord.y, 1, 1);  
                    }) 
                }
            });

        }else if(type == "hide_color"){
            // coresUnicas.forEach(element => {
            //     context.fillStyle = 'rgba(255 255 255, 0)'; 
            //     if(colorToCoordMap.get(element)){ 
            //         colorToCoordMap.get(element).forEach(coord => {
            //             context.fillRect(coord.x, coord.y, 1, 1);  
            //         }) 
            //     }
            // });
            context = canvas.getContext('2d', { willReadFrequently: true });
            context.clearRect(0, 0, canvas.width, canvas.height);            
        }
    }

    function CloseOpenMask(status){
        setShowMaskFixed(status); 
        if(status == true){
            drawInitialCanvas('create_show_color'); 
        }else {
            drawInitialCanvas('hide_color'); 
        }
        window.removeEventListener('mousemove', handleMouseMove);
    }

    function OpenData(event){
        let showMask  = document.getElementById('mask_hover');
        let offSet    = showMask.getBoundingClientRect();
        let positionX = event.clientX - offSet.left;
        let positionY = event.clientY - offSet.top;
        
        let contextTeste_ = showMask.getContext('2d');
        let dataPixelImg  = contextTeste_.getImageData(positionX, positionY, 1, 1).data;
        console.log(dataPixelImg);
        let color = dataPixelImg[0] + ", " + dataPixelImg[1] + ", " + dataPixelImg[2];
        if(color != '0, 0, 0'){
            SetModalData('DetailsApartments', { "color": color });
            SetModalState('DetailsApartments', true);
        }
    }

    useEffect(()=>{
        props.setLoading(true);
        
        setTimeout(() => {
            setStartPosition(true);
        }, 1000); 

        LoadImg();
    }, []);

    useEffect(()=>{ 
        if(listImg.length > 0){
            context   = canvas.getContext('2d', { willReadFrequently: true });
            maskHover = document.getElementById('mask_hover').getContext('2d', { willReadFrequently: true });

            canvas.addEventListener('mousemove', (e)=>{ handleMouseMove(e) });

            canvas.width  = document.getElementById('mask').clientWidth;
            canvas.height = document.getElementById('mask').clientHeight;

            maskHover.canvas.width  = canvas.width;
            maskHover.canvas.height = canvas.height;

            drawInitialCanvas('show_color');
        }
    }, [loadingFinished]);

    useEffect(()=>{
        if(startPosition == true){
            AltSmoothStart()
        }
    }, [position]);

    return(
        <div className="Page_Apartments">
            <div className="img_sun" id="mask">
                <canvas className="canvas canvas_hover" id="mask_hover" />
                <canvas className="canvas" id="canvas_teste" onClick={ (e)=>{ OpenData(e) } } />
                {
                    listImg.map((elem, index)=>{
                        return(
                            <Img listImg={ elem.url } showImg={ index == smooth ? true : false } key={ index } />
                        )
                    })
                }
            </div>

            <div className="btn">
                {
                    showMaskFixed ? 
                    <div className="btn_show_hide" onClick={ ()=>{ CloseOpenMask(false); } }>
                        Ocultar máscara
                    </div> :
                    <div className="btn_show_hide" onClick={ ()=>{ CloseOpenMask(true); } }>
                        Mostrar máscara
                    </div>
                }

                <div className="show_icon">
                    <div className={ inputRadio == 0 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(0) } }>1</div>
                    <div className={ inputRadio == 1 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(1) } }>2</div>
                    <div className={ inputRadio == 2 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(2) } }>3</div>
                    <div className={ inputRadio == 3 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(3) } }>4</div>
                    <div className={ inputRadio == 4 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(4) } }>5</div>
                    <div className={ inputRadio == 5 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(5) } }>6</div>
                    <div className={ inputRadio == 6 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(6) } }>7</div>
                    <div className={ inputRadio == 7 ? "div_sun_icon icon_active" : "div_sun_icon" } onClick={ ()=>{ OpenClick(7) } }>8</div>
                </div>
                <div className="line_position_sun" onClick={ ()=>{ } }>
                    <input type="range" className="input_range" min="0" max={ 7 } step={ 1 } onChange={ (e)=>{ OpenClick(e.target.value) } } value={ inputRadio } />
                </div>
            </div>
        </div>
    )
}