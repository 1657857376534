import { useState, useEffect } from "react";

import Page_Map from "Page/Map";
import Page_Zoom from "Page/Zoom";
import Page_Home from "Page/Home";
import Page_Solar from "Page/Solar";
import Page_Amenities from "Page/Amenities";
import Page_Apartments from "Page/Apartments";
import Page_GeneralTour from "Page/GeneralTour";
import Page_GeneralTourPoint from "Page/GeneralTourPoint";

export default function App() {

    // const folder = 'http://localhost/React_JS/chroma_rotation/dash_movala/public/assets/';
    const folder = 'https://dash-movala.chroma-garden.com/assets/';
    
    const [ loading, setLoading ]   = useState(false);
    const [ namePage, setNamePage ] = useState('index');

    function CurrentData(){
        switch (namePage) {
            case "index":
                return <Page_Home setLoading={ setLoading } folder={ folder } />;

            case "general_tour":
                return <Page_GeneralTour setLoading={ setLoading } folder={ folder } />;

            case "general_tour_point":
                return <Page_GeneralTourPoint setLoading={ setLoading } folder={ folder } />;
                
            case "solar":
                return <Page_Solar setLoading={ setLoading } folder={ folder } />;
                
            case "amenities":
                return <Page_Amenities setLoading={ setLoading } folder={ folder } />;
                
            case "zoom":
                return <Page_Zoom setLoading={ setLoading } folder={ folder } />;

            case "apartments":
                return <Page_Apartments setLoading={ setLoading } folder={ folder } />;
                
            case "map":
                return <Page_Map setLoading={ setLoading } folder={ folder } />;
        }
    }

    useEffect(()=>{
        document.body.style.backgroundImage = "url('./assets/bg.jpg')";
    }, []);

    useEffect(()=>{
        CurrentData();
    }, [namePage]);

    return(
        <div className="Preview">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <section className="dots-container">
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                </section>
            </div>

            <div className="div_contents">
                <div className="show_data_project">
                    { CurrentData() }
                </div>
            </div>

            <div className="div_btn"> 
                <div className="show_btn">
                    <div className={ namePage == "index" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('index'); } }>
                        Início
                    </div>
                    <div className={ namePage == "general_tour" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('general_tour'); } }>
                        Giro Geral
                    </div>
                    <div className={ namePage == "general_tour_point" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('general_tour_point'); } }>
                        Giro com ponto
                    </div>
                    <div className={ namePage == "solar" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('solar'); } }>
                        Solar
                    </div>
                    <div className={ namePage == "amenities" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('amenities'); } }>
                        Amenities
                    </div>
                    <div className={ namePage == "apartments" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('apartments'); } }>
                        Apartments
                    </div>
                    <div className={ namePage == "zoom" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('zoom'); } }>
                        Zoom
                    </div>
                    <div className={ namePage == "map" ? "btn_name btn_name_active" : "btn_name" } onClick={ ()=>{ setNamePage('map'); } }>
                        Mapa
                    </div>
                </div>
            </div>
        </div>
    );
}