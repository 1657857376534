export const ListMask = [
    { "mask": new Image() },
    { "mask": new Image() },
    { "mask": new Image() },
    { "mask": new Image() },
    { "mask": new Image() },
    { "mask": new Image() },
    { "mask": new Image() },
    { "mask": new Image() }
]

export const CanvasMask = {
    canvasHover: null,
    canvasFixed: null,
    mapColor: new Map()
};

export const ColorCanvas = {
    fixed: "rgba(0, 255, 0, 0.3)",
    hover: "rgba(255, 0, 0, 0.3)",
    remuve: "rgba(0, 0, 0, 1)"
}