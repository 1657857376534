
let DataPage = {
    "general_tour"    : [],
    "show_point": false,
    "position_click": [],
    "screen_initial"  : [],
    "solar"           : [],
    "apartments"      : [],
    "apartments_file" : [],
    "map_poi"         : [],

    "zoom": []
};

let NotifyDataPage = {
    "general_tour"    : [],
    "show_point": [],
    "position_click": [],
    "screen_initial"  : [],
    "solar"           : [],
    "apartments"      : [],
    "apartments_file" : [],
    "map_poi"         : [],

    "zoom": []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["general_tour"].forEach(element => {    element(value['general_tour']); });
    NotifyDataPage["show_point"].forEach(element => {    element(value['show_point']); });
    NotifyDataPage["position_click"].forEach(element => {    element(value['position_click']); });
    NotifyDataPage["screen_initial"].forEach(element => {  element(value['screen_initial']); });
    NotifyDataPage["solar"].forEach(element => {           element(value['solar']); });
    NotifyDataPage["apartments"].forEach(element => {      element(value['apartments']); });
    NotifyDataPage["apartments_file"].forEach(element => { element(value['apartments_file']); });
    NotifyDataPage["map_poi"].forEach(element => {         element(value['map_poi']); });
    NotifyDataPage["zoom"].forEach(element => {            element(value['zoom']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}