import React, { useState, useEffect } from "react";

import './DetailsApartments.css';

import { GetDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgClose } from "SvgFile";

export default function PopUP_DetailsApartments(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ showData, setShowData ] = useState("");

    function InitialData(){
        let colorApartments = GetDataPage('zoom').point_zoom.find(item => item.id == modalData.position);

        if(colorApartments.apartments.find(item => item.color == modalData.color)){
            let infApartments = colorApartments.apartments.find(item => item.color == modalData.color);
            return infApartments;
        }else {
            return '';
        }
    }

    function ClosePopUp(){
        setShowData("");
        SetModalState('DetailsApartments', false);
    }

    useEffect(()=>{
        RegisterModalData('DetailsApartments', setModaldata);
        RegisterModalObserver('DetailsApartments', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setShowData(InitialData());
        }
    }, [showPopUp]);

    return(
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all DetailsApartments">
                        
                        <div className="div_data">
                            <div className="title">Dados do apartamento</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#ffffff" className="icons" />
                            </div>
                        </div>
                        <div className="div_data show_content">
                            {
                                showData.img == undefined ?
                                <div className="content">
                                    <div className="div_img_apartments">
                                        Nenhuma informação definida para o apartamento
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="content">
                                        <div className="div_img_apartments">
                                            <div className="img" style={ { backgroundImage: 'url("' + showData.img + '")' } } />
                                        </div>
                                    </div>
                                    
                                    <div className="content">
                                        <div className="div_title_apartments">
                                            { showData.title }
                                        </div>
                                    </div>
                                    <div className="content div_inf_apartments">
                                        <div className="div_data_apartments">
                                            <div className="div_type_apartments">Sq ft</div>
                                            <div className="div_data_apartments">{ showData.size }</div>
                                        </div>
                                        <div className="div_data_apartments">
                                            <div className="div_type_apartments">BEDROOMS</div>
                                            <div className="div_data_apartments">{ showData.bedrooms }</div>
                                        </div>
                                        <div className="div_data_apartments">
                                            <div className="div_type_apartments">ORIENTATION</div>
                                            <div className="div_data_apartments">{ showData.guidance }</div>
                                        </div>
                                        <div className="div_data_apartments">
                                            <div className="div_type_apartments">Number</div>
                                            <div className="div_data_apartments">{ showData.number }</div>
                                        </div>
                                        <div className="div_data_apartments">
                                            <div className="div_type_apartments">Status</div>
                                            <div className="div_data_apartments">{ showData.status }</div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    )
};
