import { useState, useEffect, useRef } from "react";

import './Zoom.css';

import { SvgZoom } from "SvgFile";

import PopUP_DetailsApartments from "PopUp/DetailsApartments";

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Zoom(props){

    const [ squareWidth, setSquareWidth ]   = useState(400);
    const [ squareHeight, setSquareHeight ] = useState(260);

    const [ scale, setScale ]           = useState(1.5);    
    const [ filter, setFilter ]         = useState(true);
    const [ magnifying, setMagnifying ] = useState(false);
    const [ position, setPosition ]     = useState(1); // position initial - 0
    const [ pointZoom, setPointZoom ]   = useState(0);
    const [ showData, setShowData ]     = useState(GetDataPage('zoom'));

    /* canvas - mask */
    let canvas  = null;
    let context = null; 

    let maskHover    = null;
    let contextHover = null;

    let sold        = 'rgba(255, 0, 0, 0.3)';
    let available   = 'rgba(0, 255, 0, 0.3)';
    let transparent = 'rgba(255, 255, 255, 0)';

    let colorToCoordMap = new Map();
    /* end */

    function CurrentPage(){
        // initial
        if(position == 0){
            return(
                <>
                    {
                        showData.point_zoom.map((elem, index)=>{
                            return(                 
                                <div className="btn_zoom" onClick={ ()=>{ setPosition(elem.id) } } key={ index } style={ { top: elem.top, left: elem.left } }>
                                    <SvgZoom className="icons" color="#76c57e" />
                                </div>
                            )
                        })
                    }
                    <img className="img_bg" id="imagem" alt="img" src={ showData.img } />
                </>
            )
        }
        // show data zoom
        if(position !=0){
            const newData = showData.point_zoom.find(item => item.id == position);
            let dataPoint = newData.point.find(item => item.id == pointZoom);

            return(
                <>
                    {
                        pointZoom == 0 ? 
                        newData.point.map((elem, index)=>{
                            return(                 
                                <div className="btn_zoom" onClick={ ()=>{ setPointZoom(elem.id) } } key={ index } style={ { top: elem.top, left: elem.left } }>
                                    <SvgZoom className="icons" color="#F00000" />
                                </div>
                            )
                        }) : null
                    }
                    
                    <div className="div_search">
                        {
                            pointZoom == 0 ? 
                            <div className="btn_search" onClick={ ()=>{ setPosition(0); setFilter(false); setMagnifying(false); } }>
                                Voltar ao inicio
                            </div>
                            :
                            <div className="btn_search" onClick={ ()=>{ setPointZoom(0); setFilter(false); setMagnifying(false); } }>
                                Voltar
                            </div>
                        }
                        <div className="div_btn_search">                        
                            <div className="btn_search" onClick={ ()=>{ CreateMask(!filter); }}>
                                {
                                    filter ? "Cancelar filtro" : "Filtar imagem"
                                }
                            </div>
                            {
                                filter ? 
                                <div className="div_legend">
                                    <div className="legend" onClick={ ()=>{ generateColorCoordMap(); OpenMask('available') } } style={ { background: 'rgb(0, 255, 0)', color: 'rgb(255, 0, 0)' } }>
                                        Disponível
                                    </div>
                                    <div className="legend" onClick={ ()=>{ generateColorCoordMap(); OpenMask('sold') } } style={ { background: 'rgb(255, 0, 0)', color: 'rgb(0, 255, 0)' } }>
                                        Vendido
                                    </div>
                                    <div className="legend" onClick={ ()=>{ generateColorCoordMap(); OpenMask('show_color') } } style={ { background: 'rgb(11 41 201)', color: '#ffffff' } }>
                                        Mostrar todos
                                    </div>
                                </div>
                                : null
                            }
                            <div className="btn_search" onClick={ ()=>{  setMagnifying(!magnifying); } }>
                                {
                                    magnifying ? "Cancelar zoom" : "Ampliar"
                                }
                            </div>
                        </div>
                    </div>
                    {
                        magnifying ? 
                        <div className="zoom" id="zoom" style={ { width: squareWidth + "px", height: squareHeight + "px" } }>
                            <img className="add_img" id="add_img" src={ pointZoom == 0 ? newData.img : dataPoint.img } />
                        </div>
                        : 
                        null
                    }
                    <canvas className={ filter ? "canvas" : "canvas_none" } id="canvas_hover" />
                    <canvas className={ filter ? "canvas canvas_hover" : "canvas_none" } id="canvas_mask" onClick={ (e)=>{ OpenData(e) } } />

                    <img className="img_bg" id="imagem" alt="img" src={ pointZoom == 0 ? newData.img : dataPoint.img } />
                </>
            )
        }
    }

    function ZoomImg(){
        if(document.getElementById('imagem')){
            let zoom   = document.getElementById('zoom');
            let imagem = document.getElementById('imagem');
            let addImg = document.getElementById('add_img');

            addImg.style.width  = imagem.clientWidth * scale + "px";
            addImg.style.height = imagem.clientHeight * scale + "px";

            imagem.addEventListener('mouseover', function() {
                zoom.style.display = 'block';
            });

            imagem.addEventListener('mousemove', function(event) {
                addImg.style.left = -((event.clientX * scale) - (squareWidth / 2)) + 'px';
                addImg.style.top  = -((event.clientY * scale) - (squareHeight / 2)) + 'px';

                let backgroundX = event.clientX - (squareWidth / 2);
                let backgroundY = event.clientY - (squareHeight / 2);

                zoom.style.backgroundPosition = `${ backgroundX }px ${ backgroundY }px`;
                zoom.style.left = (event.clientX - (squareWidth / 2)) + 'px';
                zoom.style.top  = (event.clientY - (squareHeight / 2)) + 'px';
            });

            imagem.addEventListener('mouseout', function() {
                zoom.style.display = 'none';
            });
        }
    }

    function CreateMask(value){    
        const newData    = showData;        
        const dataPoint  = newData.point_zoom.find(item => item.id == position);
        let   pointIndex = newData.point_zoom.findIndex(item => item.id == position);

        fetch(dataPoint.img_mask).then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader   = new FileReader();
            reader.onload  = ()=>{
                
                // dataPoint.url = reader.result;
                newData.point_zoom[pointIndex].url = reader.result;

            }
            reader.onerror = reject
            reader.readAsDataURL(blob);
        }));

        setShowData(newData);
        setFilter(value);
        if(value){
            setTimeout(() => {
                SearchFilter();
            }, 100);
        }
    }

    function SearchFilter(){  
        const newData = showData.point_zoom.find(item => item.id == position);
        let dataPoint = newData.point.find(item => item.id == pointZoom);

        const width   = document.getElementById('mask').clientWidth;
        const height  = document.getElementById('mask').clientHeight;

        // Canvas color mask
        canvas        = document.getElementById('canvas_mask');    
        context       = canvas.getContext('2d', { willReadFrequently: true });
        canvas.width  = width;
        canvas.height = height;
        let imagem = new Image();
        imagem.onload = function() {
            context.drawImage(imagem, 0, 0, width, height);            
            generateColorCoordMap();
        };
        if(pointZoom == 0){
            imagem.src = newData.img_mask;  
        }else {
            imagem.src = dataPoint.img_mask;   
        }     
        canvas.addEventListener('mousemove', (e)=>{ handleMouseMove(e) } );
        // End

        // Canvas color effect hover
        maskHover    = document.getElementById('canvas_hover').getContext('2d', { willReadFrequently: true });
        contextHover = maskHover;
        maskHover.canvas.width  = width;
        maskHover.canvas.height = height;
        // End
    }

    function generateColorCoordMap() {
        colorToCoordMap.clear();

        canvas  = document.getElementById('canvas_mask');    
        context = canvas.getContext('2d', { willReadFrequently: true });
        const maskData = context.getImageData(0, 0, canvas.width, canvas.height).data;
        for (let i = 0; i < maskData.length; i += 4) {
            const r = maskData[i];
            const g = maskData[i + 1];
            const b = maskData[i + 2];
            const a = maskData[i + 3];

            if ((r > 0 || g > 0 || b > 0)) {
                const color = `rgb(${r}, ${g}, ${b})`;
                if (!colorToCoordMap.has(color)) {
                    colorToCoordMap.set(color, []);
                }else {
                    colorToCoordMap.get(color).push({ x: (i / 4) % canvas.width, y: Math.floor(i / 4 / canvas.width) });
                }
            }
        }
        OpenMask('show_color', '');
    }

    function handleMouseMove(event) {
        requestAnimationFrame(() => {
            // OpenMask('show_color', '');

            // const rect = canvas.getBoundingClientRect();
            // const x    = event.clientX - rect.left;
            // const y    = event.clientY - rect.top;

            // const pixelData    = canvas.getContext('2d').getImageData(x, y, 1, 1).data;
            // const currentColor = `rgb(${ pixelData[0] }, ${ pixelData[1] }, ${ pixelData[2] })`;

            // if (pixelData[0] > 0 || pixelData[1] > 0 || pixelData[2]) {
            //     if(colorToCoordMap.get(currentColor)){
            //         contextHover.fillStyle = sold;
            //         colorToCoordMap.get(currentColor).forEach(coord => {
            //             contextHover.fillRect(coord.x, coord.y, 1, 1);
            //         });
            //     }
            // }else {
            //     OpenMask('return_color', currentColor);
            // }
        });
    }

    function OpenMask(type){
        setTimeout(() => {
            if(document.getElementById('canvas_mask').getContext('2d')){
                let showWidth  = document.getElementById('canvas_mask').clientWidth;
                let showHeight = document.getElementById('canvas_mask').clientHeight;

                let canvasHoverColor = document.getElementById('canvas_mask').getContext('2d');
                const imageData      = canvasHoverColor.getImageData(0, 0, showWidth, showHeight);
                const data           = imageData.data;

                const coresUnicas = [];
                for (let i = 0; i < data.length; i += 4) {
                    const cor = `rgb(${data[i]}, ${data[i + 1]}, ${data[i + 2]})`; 
                    if (!coresUnicas.includes(cor)) {
                        coresUnicas.push(cor);
                    }
                }
                
                const indexColor = coresUnicas.indexOf('rgba(0, 0, 0, 1)');
                coresUnicas.splice(indexColor, 1);
                
                contextHover = document.getElementById('canvas_hover').getContext('2d', { willReadFrequently: true });
                contextHover.clearRect(0, 0, showWidth, showHeight);
                coresUnicas.forEach(element => {
                    if(colorToCoordMap.get(element)){                        
                        
                        let colorApartments = showData.point_zoom.find(item => item.id == position);
                        if(colorApartments.apartments.find(item => item.color == element)){
                            let infApartments = colorApartments.apartments.find(item => item.color == element);

                            if(infApartments.status == 'available'){
                                if(type != "sold"){
                                    contextHover.fillStyle = available; 
                                }else {
                                    contextHover.fillStyle = transparent;
                                }

                            }else if(infApartments.status == 'sold'){
                                if(type != "available"){
                                    contextHover.fillStyle = sold; 
                                }else {
                                    contextHover.fillStyle = transparent;
                                }
                            }

                        }else {
                            if(type == "sold"){
                                contextHover.fillStyle = transparent;
                            }else {
                                contextHover.fillStyle = available;
                            }
                        } 
                        
                        colorToCoordMap.get(element).forEach(coord => {
                            contextHover.fillRect(coord.x, coord.y, 1, 1);
                        });
                    }
                })
            }
        }, 300);
    }

    function OpenData(event){
        let showMask  = document.getElementById('canvas_mask');
        let offSet    = showMask.getBoundingClientRect();
        let positionX = event.clientX - offSet.left;
        let positionY = event.clientY - offSet.top;
        
        let contextTeste_ = showMask.getContext('2d');
        let dataPixelImg  = contextTeste_.getImageData(positionX, positionY, 1, 1).data;

        let color = dataPixelImg[0] + ", " + dataPixelImg[1] + ", " + dataPixelImg[2];
        if(color != '0, 0, 0'){
            SetModalData('DetailsApartments', { "color": "rgb(" + color + ")", "position": position });
            SetModalState('DetailsApartments', true);
        }
    }

    useEffect(()=>{
        if(filter){
            CreateMask(true);
        }
    }, []);

    useEffect(()=>{
        if(magnifying){
            ZoomImg();
        }
    }, [magnifying]);

    useEffect(()=>{

    }, [filter]);

    return(
        <div className="Page_Zoom">
            <div className="img_bg" id="mask">
                { CurrentPage() }
            </div>
            <PopUP_DetailsApartments />
        </div>
    )
}