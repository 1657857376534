import React, { useState, useEffect } from "react";


import Iframe from 'react-iframe';

import './OpenData.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgClose } from "SvgFile";

export default function PopUP_OpenData(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('OpenData', setModaldata);
        RegisterModalObserver('OpenData', setShowPopUp);
    }, []);

    function ClosePopUp(){
        SetModalState('OpenData', false);
    }

    function OpenFile(){
        switch (modalData.data.type) {
            case 'image':
                return <img alt="img" src={ "./assets/disassemble/0001/point/" + modalData.data.file } className="show_img" />;

            case 'showPdf':
                return <Iframe url={ "./assets/disassemble/0001/file/" + modalData.data.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'vimeo':
                let linkVimeo = modalData.data.video.split('/');
                return <Iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[3] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'youtube':
                let linkYoutube = modalData.data.video.split('=');
                return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'text':
                return <div className="text" dangerouslySetInnerHTML={ { __html: modalData.data.text ? modalData.data.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />;
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all open_data" style={ { backgroundColor: '#2d3c53' } }>
                        <div className="div_data type_div">
                            <div className="title">{ modalData.title }</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    OpenFile()
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
