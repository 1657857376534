import { useState, useEffect, useRef } from "react";

import './Amenities.css';

import Img from "Img";

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import PopUP_OpenData from "PopUp/OpenData";

export default function Page_Amenities(props){

    const [ dataPage, setDataPage ]           = useState(GetDataPage('disassemble'));
    const [ statusInitial, setStatusInitial ] = useState('initial');

    const [ position, setPosition ]       = useState(0);
    const [ smooth, setSmooth ]           = useState(0);
    const [ oldPosition, setOldPosition ] = useState(0);
    
    const [ listImg, setListImg ] = useState([]);

    const [ startPosition, setStartPosition ] = useState(false);
    let speed   = 0;
    let time    = 0;
    let elapsed = 0;
    
    function LoadImg(){
        let count = 0;
        const newDate = [];

        dataPage[0].list_img.map((elem, index)=>{
            fetch(props.folder + "disassemble/" + dataPage[0].code + "/file/" + elem.img).then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader   = new FileReader();
                reader.onload  = ()=>{
                    count++;
                    newDate[index] = { "id": index, "url": reader.result, "img": elem.img, "list_point": elem.list_point };

                    if(dataPage[0].list_img.length == count){
                        setListImg([...newDate]);

                        props.setLoading(false);
                    }
                }
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
        });
    }

    function AltMovieAnimation(){
        const now = Date.now();
        const deltaTime = (now - time) / 1000;
        elapsed += deltaTime;
        time = now;

        let delta = elapsed * speed;
        if(delta >= 1){
            setSmooth(position);
            setOldPosition(position);
        }else {
            setSmooth(Math.round(lerp(oldPosition, position, delta)));
            window.requestAnimationFrame(AltMovieAnimation);
        }
    }

    function AltSmoothStart(){
        speed = (1 - (Math.abs(position - oldPosition) / (dataPage[0].list_img.length - 1))) * 10;        
        elapsed = 0;
        time    = Date.now();
        AltMovieAnimation();
    }

    function lerp (start, end, amt){
        return (1-amt) * start + amt * end;
    }

    function OpenClick(type, index){
        if(type == 'initial'){
            AltSmoothStart()
            let count = position;
            const countSetInterval = setInterval(() => {
                count--;
                setPosition(count);
                if(count == 0){
                    clearInterval(countSetInterval);
                }
            }, 16);

        }else {

            AltSmoothStart()
            let count    = position;
            let countMid = dataPage[0].list_img.indexOf(dataPage[0].list_img.find(item => item.img == type));
            const countSetInterval = setInterval(() => {
                if(countMid > count){
                    count++;
                }else if(countMid < count){
                    count--;
                }
                setPosition(count);
                if(count == countMid){
                    clearInterval(countSetInterval);
                }
            }, 16);
        }
    }

    function OpenData(title, data){
        SetModalData('OpenData', { "title": title, "data": data });
        SetModalState('OpenData', true);
    }

    useEffect(()=>{
        props.setLoading(true);
        
        setTimeout(() => {
            setStartPosition(true);
        }, 1000);        

        LoadImg();
    }, []);

    useEffect(()=>{
        if(startPosition == true){
            AltSmoothStart()
        }
    }, [position]);

    return(
        <div className="Page_Amenities">
            <div className="show_img_sun">
                {
                    listImg.map((elem, index)=>{
                        return(
                            <div className={ "div_img point_" + index } key={ index }>
                                <Img listImg={ elem.url } showImg={ index == smooth ? true : false } />
                                <div className="list_point">
                                    {
                                        statusInitial != elem.img ? null :
                                        elem.list_point.map((elem_1, index_1)=>{
                                            let positionY = 0;
                                            let positionX = 0;

                                            let widthDiv  = document.querySelector('.point_' + index).clientWidth;
                                            let heightDiv = document.querySelector('.point_' + index).clientHeight;
                                            
                                            positionY = heightDiv * elem_1.y;
                                            positionX = widthDiv * elem_1.x;
                                            
                                            return(
                                                <div className={ statusInitial != elem.img ? "point" : "point point_animation" } key={ index_1 } style={ { top: positionY + "px", left: positionX + "px" } }>
                                                    <div className="name_point" onClick={ ()=>{ OpenData(elem_1.title, elem_1) }}>
                                                        {
                                                            elem_1.title == "" ? "Não definido" : elem_1.title
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="show_btn">
                <div className={ statusInitial == "initial" ? "name_btn btn_active" : "name_btn" } onClick={ ()=>{ OpenClick('initial'); setStatusInitial('initial'); } }>
                    Estado inicial
                </div>
                {
                    dataPage[0].point_img.map((elem, index)=>{
                        if(elem.name_btn !=''){
                            return(
                                <div className={ statusInitial == elem.img ? "name_btn btn_active" : "name_btn" } key={ index } onClick={ ()=>{ OpenClick(elem.img); setStatusInitial(elem.img); } }>
                                    { elem.name_btn }
                                </div>
                            )
                        }
                    })
                }
            </div>
            <PopUP_OpenData />
        </div>
    )
}