import { useState, useEffect } from "react";

import './Map.css';

import { GetDataPage } from "interface/Data";

import GoogleMapReact from 'google-map-react';

export default function Page_Map(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('map_poi'));

    const [ latitude, setLatitude ]   = useState(dataPage.latitude);
    const [ longitude, setLongitude ] = useState(dataPage.longitude);
    const [ listPoint, setListPoint ] = useState(dataPage.list_point);

    const [ listCategory, setListCategory ] = useState(ShowCategory());
    const [ idCategory, setIdCategory ]     = useState(0);

    const AnyReactComponent = ({ text, img_src, showImage, className }) => <div className={ "show_point " + className } style={ { display: showImage ? 'block' : 'none' } }><img src={ img_src } className={ "img_point " + className } /><span className="text">{ text }</span></div>;

    const defaultMapOptions = {
        styles: [
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]
    };

    const style = [        
        {
            elementType: "geometry", 
            stylers: [
                {
                    color: "#e0cab5" 
                }
            ] 
        },

        {
            featureType: "poi",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },

        {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        
        { 
            elementType: "labels.text.fill", 
            stylers: [
                {
                    visibility: 'off'
                }
            ] 
        },
        {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [
                {
                    color: "#848d70"
                },
                {
                    padding: "10px"
                }
            ]
        },
        {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },

        {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            elementType: "labels.text.stroke", 
            stylers: [
                { 
                    color: "#ffffff" 
                }
            ] 
        },

        {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        },
        {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [
                {
                    visibility: 'off'
                }
            ]
        }
    ]

    function ShowCategory(){
        const newData = [];
        listPoint.map((elem, index)=>{
            if(newData.find(item => item.id == elem.id_category)){ }else {
                newData.push({ "id": elem.id_category, "name": elem.name_categoory });
            }
        })
        newData.sort((a, b) => {
            const nameA = a.name;
            const nameB = b.name;

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        return newData;
    }

    function OpenCategory(id, value, index){
        const newData = [...listPoint];
        newData.map((elem, index)=>{
            if(id == idCategory){
                elem.statusIcon = true;
            }else{
                if(value){
                    if(elem.id_category == id){ 
                        elem.statusIcon = value;
                    }else {  
                        elem.statusIcon = false;
                    }
                }else {
                    elem.statusIcon = true;  
                }
            }
        })

        if(id == idCategory){
            setIdCategory(0);
        }else {
            setIdCategory(id);
        }
        setListPoint(newData);
    }

    function MapLoaded(dataMap){ 
        dataMap.map.setTilt(60);
        dataMap.map.setCenter({ "lat": latitude, "lng": longitude });
        dataMap.map.setZoom(17);   
        dataMap.map.mapTypeControl = true;
    }
    
    return(
        <div className="Page_Map">
            <div className="div_category">
                <div className="title">Categorias</div>
                {
                    listCategory.map((elem, index)=>{
                        return(
                            <div className={ idCategory == elem.id ? "name_category category_active" : "name_category" } key={ index } onClick={ ()=>{ OpenCategory(elem.id, !elem.statusIcon, index) }}>
                                { elem.name }
                            </div>
                        )
                    })
                }
            </div>
            <div className="div_map">
                <GoogleMapReact 
                    bootstrapURLKeys={ { key: "AIzaSyC4EnMG7H-fmsU4vXtSvLVhnzPZTEqS4nw" } }
                    defaultCenter={ { "lat": latitude, "lng": longitude } }
                    defaultZoom={ 17 }
                    // onChange={ (e)=>{ CheckedZoom(e) }}
                    options={ {
                        // mapId: "df32285f2c0c7c44",
                        mapId: "d974813bd5866c5f",
                        styles: style
                    } }             
                    
                    defaultOptions={ defaultMapOptions }

                    onGoogleApiLoaded={ (dataMap)=>{ MapLoaded(dataMap) } }
                    yesIWantToUseGoogleMapApiInternals
                >
                    {
                        listPoint.map((elem, index)=>{
                            let typeClassSize = "";
                            switch (elem.type) {
                                case 1: case '1': typeClassSize = "opt_1 id_ctg_" + elem.id_category; break;
                                case 2: case '2': typeClassSize = "opt_2 id_ctg_" + elem.id_category; break;
                                case 3: case '3': typeClassSize = "opt_3 id_ctg_" + elem.id_category; break;
                            } 

                            return(
                                <AnyReactComponent
                                    key= { index }
                                    lat= { elem.latitude }
                                    lng= { elem.longitude }
                                    text= { elem.title }
                                    className={ typeClassSize }
                                    showImage={ elem.statusIcon }
                                    img_src= { props.folder + "point_map/" + elem.icon }
                                />
                            )
                        })
                    }
                </GoogleMapReact>
            </div>
        </div>
    )
}